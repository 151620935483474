import request from '@/utils/request'
const BACK_API = '/back/api'
const BASE_PATH = `${BACK_API}/admin`

const getDepts = (query) => {
  return request({
    url: `${BASE_PATH}/permission/dept/`,
    method: 'get',
    params: query
  })
}

const getDept = (id) => {
  return request({
    url: `${BASE_PATH}/permission/dept/${id}/`,
    method: 'get'
  })
}

const addDept = (payload) => {
  return request({
    url: `${BASE_PATH}/permission/dept/`,
    method: 'post',
    data: payload
  })
}

const editDept = (payload) => {
  return request({
    url: `${BASE_PATH}/permission/dept/${payload.id}/`,
    method: 'put',
    data: payload
  })
}

const getParentDepts = (parentId) => {
  return request({
    url: `${BASE_PATH}/permission/dept/parent/${parentId}/`,
    method: 'get'
  })
}

const delDept = (id) => {
  return request({
    url: `${BASE_PATH}/permission/user/${id}/`,
    method: 'delete'
  })
}

export { 
  getDepts,
  getDept,
  addDept,
  editDept,
  getParentDepts,
  delDept 
}
