import request from '@/utils/request'
const BACK_API = '/back/api'
const BASE_PATH = `${BACK_API}/admin`

const getUser = (query) => {
  return request({
    url: `${BASE_PATH}/permission/user/`,
    method: 'get',
    params: query
  })
}

const addUser = (payload) => {
  return request({
    url: `${BASE_PATH}/permission/user/`,
    method: 'post',
    data: payload
  })
}

const editUserPwd = (payload) => {
  return request({
    url: `${BASE_PATH}/permission/user/profile/updatePwd/${payload.id}`,
    method: 'put',
    data: payload
  })
}

export { 
  getUser,
  addUser,
  editUserPwd
}
