<template>
  <vp-base-layout
    page-title="대리점목록"
    page-default-back-link="/">
    <ion-list lines="full">
      <ion-item>
        <ion-label>대리점명</ion-label>
        <ion-input
          placeholder="대리점명을 입력해주세요."
          v-model="agencyInfo.deptName" />
      </ion-item>
      
      <ion-item>
        <ion-label>대표자명</ion-label>
        <ion-input
          placeholder="대표자명을 입력해주세요."
          v-model="agencyInfo.name" />
      </ion-item>

      <ion-item>
        <ion-label>전화번호</ion-label>
        <ion-input
          type="tel"
          placeholder="ex)010-1234-1234"
          v-model="agencyInfo.mobile" />
      </ion-item>

      <ion-item>
        <ion-label>ID</ion-label>
        <ion-input
          :disabled="isDisable"
          placeholder="아이디를 입력해주세요."
          v-model="agencyInfo.username" />
      </ion-item>

      <ion-item>
        <ion-label>Password</ion-label>
        <ion-input
          type="password"
          placeholder="비밀번호를 입력해주세요."
          v-model="agencyInfo.password" />
      </ion-item>
    </ion-list>

    <vp-button
      v-if="isDisable"
      expand="full"
      :types="[{'fixed': fixed}]"
      @click="handleCreateAgencyUser">
      대리점 정보 수정
    </vp-button>
    <vp-button
      v-else
      expand="full"
      :types="[{'fixed': fixed}]"
      @click="handleCreateAgencyUser">
      신규 대리점 등록
    </vp-button>
  </vp-base-layout>
</template>
<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { IonList, IonItem, IonInput, IonLabel, useIonRouter } from '@ionic/vue'
import { ref, reactive, inject, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import md5 from 'js-md5'
import { getDepts, addDept, editDept } from '@/api/permission/dept'
import { addUser, editUserRole, getUser, getUsername, editUserPwd } from '@/api/permission/user'
import { getToken } from '@/utils/auth'

//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const fixed = ref(true)
const isDisable = ref(false)

const ionRouter = useIonRouter()
const token = getToken()
const jwtDecode = inject('$jwtDecode')
const user = jwtDecode(token)

const agencyInfo = reactive({})

const handleCreateAgencyUser = async () => {
  const param = {
    pageNum: 1,
    pageSize: 999999999999999
  }
  const response = await getDepts(param)
  const myDept = response.data.results.filter((obj) => obj.id === user.dept_id)[0]
  const selfIdx = response.data.results.findIndex((obj) => obj.id === user.dept_id)
  response.data.results.splice(selfIdx, 1)

  agencyInfo.status = myDept.status
  agencyInfo.parentId = user.dept_id
  agencyInfo.orderNum = Math.max(...response.data.results.map((obj) => obj.orderNum)) + 1
  agencyInfo.password = md5(agencyInfo.password)

  const userPayload = {
    is_active: true,
    mobile: agencyInfo.mobile,
    name: agencyInfo.name,
    username: agencyInfo.username,
    password: agencyInfo.password,
    postIds: [],
    roleIds: [56]
  }

  if (route?.params?.id) {
    const id = Number(route.params.id)
    userPayload.deptId = id
    userPayload.id = id
    await editDept(userPayload)
    await editUserPwd(userPayload)
  } else {
    const deptInfo = await addDept(agencyInfo)
    userPayload.deptId = deptInfo.data.id
    await addUser(userPayload)
  }
  // ionRouter.push('/agency-list')
  //location.href = '/agency-list'
  location.reload()
}
//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
const route = useRoute()
onMounted(async () => {
  if (route?.params?.id) {
    isDisable.value = true
    const param = {
      pageNum: 1,
      pageSize: 999999999999999
    }
    const id = Number(route.params.id)
    const response = await getUser(param).then((res) => res.data.results)
    const userInfo = response.filter((obj) => Number(obj.deptId) === id)[0]
    const deptInfo = userInfo.dept
  
    agencyInfo.deptName = deptInfo.deptName
    agencyInfo.name = userInfo.name
    agencyInfo.mobile = userInfo.mobile
    agencyInfo.username = userInfo.username
  } else {
    isDisable.value = false
  }

})
</script>
